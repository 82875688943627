@tailwind utilities;

@layer utilities {
  /* Body */

  .type-body-xs,
  .type-body-sm,
  .type-body-base,
  .type-body-md,
  .type-body-lg,
  .type-body-xl,
  .type-body-2xl,
  .type-body-3xl,
  .type-body-4xl,
  .type-body-5xl,
  .type-body-6xl,
  .type-body-8xl {
    @apply font-bulo not-italic;
    line-height: 1.2;
  }

  /* 12px */
  .type-body-xs {
    font-size: 0.75rem;
  }

  /* 14px */
  .type-body-sm {
    font-size: 0.875rem;
  }

  /* 16px */
  .type-body-base {
    font-size: 1rem;
  }

  /* 18px */
  .type-body-lg {
    font-size: 1.125rem;
  }

  /* 20px */
  .type-body-xl {
    font-size: 1.25rem;
  }

  /* 24px */
  .type-body-2xl {
    font-size: 1.5rem;
  }

  /* 30px */
  .type-body-3xl {
    font-size: 1.875rem;
  }

  /* 36px */
  .type-body-4xl {
    font-size: 2.25rem;
  }

  /* 48px */
  .type-body-5xl {
    font-size: 3rem;
  }

  /* 60px */
  .type-body-6xl {
    font-size: 3.75rem;
  }

  /* 96px */
  .type-body-8xl {
    font-size: 6rem;
  }

  /* 350px */
  .type-body-mega {
    font-size: 21.875rem;
  }

  /* Heading */

  .type-heading-xxs,
  .type-heading-xs,
  .type-heading-sm,
  .type-heading-base,
  .type-heading-md,
  .type-heading-lg,
  .type-heading-xl,
  .type-heading-2xl,
  .type-heading-3xl,
  .type-heading-4xl,
  .type-heading-5xl,
  .type-heading-6xl {
    @apply font-pfdin tracking-widest2x uppercase not-italic;
    line-height: 1.2;
  }

  /* 10px */
  .type-heading-xxs {
    font-size: 0.625rem;
  }

  /* 12px */
  .type-heading-xs {
    font-size: 0.75rem;
  }

  /* 14px */
  .type-heading-sm {
    font-size: 0.875rem;
  }

  /* 16px */
  .type-heading-base {
    font-size: 1rem;
  }

  /* 18px */
  .type-heading-lg {
    font-size: 1.125rem;
  }

  /* 20px */
  .type-heading-xl {
    font-size: 1.25rem;
  }

  /* 24px */
  .type-heading-2xl {
    font-size: 1.5rem;
  }

  /* 30px */
  .type-heading-3xl {
    font-size: 1.875rem;
  }

  /* 36px */
  .type-heading-4xl {
    font-size: 2.25rem;
  }

  /* 48px */
  .type-heading-5xl {
    font-size: 3rem;
  }

  /* 60px */
  .type-heading-6xl {
    font-size: 3.75rem;
  }
}
