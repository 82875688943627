@import "react-toastify/dist/ReactToastify.css";

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #273b28;
  --toastify-color-warning: #3b3727;
  --toastify-color-error: #421413;
  --toastify-color-transparent: rgba(255, 255, 255, 0.5);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  /* Used only for colored theme */
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  /* Used when no type is provided */
  /* toast("**hello**") */
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );

  /* Used when no type is provided */
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

  /* Used to control the opacity of the progress trail */
  --toastify-color-progress-bgo: 0.1;
}

/* Success */

.Toastify__toast-theme--colored.Toastify__toast--success {
  @apply border border-green font-bulo font-bold text-white;
}

.Toastify__toast-theme--colored.Toastify__toast--success .Toastify__close-button > svg {
  @apply text-green;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
  @apply bg-green;
}

/* Warning */

.Toastify__toast-theme--colored.Toastify__toast--warning {
  @apply border border-yellow font-bulo font-bold text-white;
}

.Toastify__toast-theme--colored.Toastify__toast--warning .Toastify__close-button > svg {
  @apply text-yellow;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
  @apply bg-yellow;
}

/* Error */

.Toastify__toast-theme--colored.Toastify__toast--error {
  @apply border border-red font-bulo font-bold text-white;
}

.Toastify__toast-theme--colored.Toastify__toast--error .Toastify__close-button > svg {
  @apply text-red;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  @apply bg-red;
}
